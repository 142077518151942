<template>
  <div>
    <s-search
      :queryItems="queryItems"
      @fetchData="fetchUsers"
      ref="SSearch"
    ></s-search>
    <div class="table-button table-button-flex">
      <div>
        <a-button
          v-if="isShowBtns.indexOf('ConstructionProject-/api/project/add') > -1"
          type="primary"
          @click="showDrawer('ConstructionProjectEdit', '新增', 'add')"
          >新增</a-button
        >
      </div>
      <div>
        <!-- <a-button type="plain" icon="cloud-download" @click="handleExport"
          >导出</a-button
        > -->
        <a-button type="plain" icon="redo" @click="$refs.table.refresh(true)"
          >刷新</a-button
        >
        <columns-set
          tableName="ConstructionProject"
          :columns="columns"
          @changeColumns="changeColumns"
        ></columns-set>
      </div>
    </div>
    <s-table
      ref="table"
      size="small"
      rowKey="pid"
      :columns="tableColumns"
      :data="loadData"
      :showPagination="true"
      :scroll="{ x: 1300, y: 'calc(82vh - 150px )' }"
      tableLayout="fixed"
    >
      <!--  @change="changeTable" -->
      <span slot="pbcId" slot-scope="text">
        <div>{{ CompaniesResult(text) }}</div>
      </span>

      <span slot="tworkerType" slot-scope="text">
        <div>{{ text ? text.wtType : "" }}</div>
      </span>

      <span slot="tprojectType" slot-scope="text">
        <div>{{ text.ptName }}</div>
      </span>

      <span slot="psalesPrice" slot-scope="text">
        <div>{{ "￥" + text }}</div>
      </span>
      <span slot="pbuildTime" slot-scope="text">
        <div>
          {{ text != "" ? Math.round((text / 3600) * 100) / 100 + "h" : "" }}
        </div>
      </span>
      <span slot="pmaxBuildTime" slot-scope="text">
        <div>{{ text != "" ? Math.round(text / 3600) + "h" : "" }}</div>
      </span>
      <span slot="wpCreateTime" slot-scope="text, record">
        <div v-if="record.wpCreateTime == ''">{{ "-" }}</div>
        <div>{{ $Format(record.wpCreateTime).slice(0, 10) }}</div>
      </span>
      <!-- 操作 -->
      <span slot="action" slot-scope="text, record" class="action">
        <a
          class="editBtn"
          v-if="
            isShowBtns.indexOf('ConstructionProject-/api/project/update') > -1
          "
          @click="showDrawer('ConstructionProjectEdit', '修改', 'edit', record)"
          >修改</a
        >
        <a
          class="editBtn"
          v-if="isShowBtns.indexOf('ConstructionProject-/api/project/add') > -1"
          type="primary"
          @click="showDrawer('ConstructionProjectEdit', '新增', 'add')"
          >新增</a
        >
        <a-popconfirm
          v-if="
            isShowBtns.indexOf('ConstructionProject-/api/project/delete') > -1
          "
          title="确定删除此条施工项信息吗?"
          @confirm="() => onDelete(record)"
        >
          <a class="delName">删除</a>
        </a-popconfirm>
      </span>
    </s-table>
    <a-drawer
      :title="DrawerTitle"
      placement="right"
      :visible="visible"
      :after-visible-change="afterVisibleChange"
      @close="onClose"
      :width="750"
      :destroyOnClose="destroyOnClose"
    >
      <ConstructionProjectEdit
        v-if="ConstructionProjectEdit"
        :record="record"
        :type="type"
        @onClose="onClose"
      />
    </a-drawer>
  </div>
</template>

<script>
import { STable, SSearch, ColumnsSet } from "@/components";
import { SelectOptions } from "@/config/SelectOptions.js";
import { GetProjectList, DeleteProject } from "@/api/apiJF/template";
import { GetCompanies } from "@/api/device";
import ConstructionProjectEdit from "./ConstructionProjectEdit.vue";

// 搜索条件
const queryItems = [
  {
    label: "公司名称",
    value: "pBcIds",
    type: "select",
    selectOption: "CompanyListUnAll",
  },
  {
    label: "施工项名称",
    value: "pName",
    type: "input",
    // selectOption: "CompanyListUnAll",
  },
];
const columns = [
  {
    title: "施工项",
    // width: "100px",
    scopedSlots: { customRender: "pname" },
    dataIndex: "pname",
    // align: "center",
  },
  {
    title: "公司名称",
    width: "100px",
    scopedSlots: { customRender: "pbcId" },
    dataIndex: "pbcId",
    align: "center",
  },

  {
    title: "施工项编号",
    width: "130px",
    scopedSlots: { customRender: "pnumber" },
    dataIndex: "pnumber",
    align: "center",
  },
  {
    title: "施工项类型",
    width: "120px",
    scopedSlots: { customRender: "tprojectType" },
    dataIndex: "tprojectType",
    align: "center",
  },
  {
    title: "工人岗位",
    width: "120px",
    scopedSlots: { customRender: "tworkerType" },
    dataIndex: "tworkerType",
    align: "center",
  },
  {
    title: "工程计量单位",
    width: "110px",
    scopedSlots: { customRender: "punit" },
    dataIndex: "punit",
    align: "center",
  },
  {
    title: "销售单价",
    width: "80px",
    scopedSlots: { customRender: "psalesPrice" },
    dataIndex: "psalesPrice",
    align: "center",
  },
  {
    title: "单位施工时间",
    width: "110px",
    scopedSlots: { customRender: "pbuildTime" },
    dataIndex: "pbuildTime",
    align: "center",
  },
  {
    title: "日施工时长",
    width: "100px",
    scopedSlots: { customRender: "pmaxBuildTime" },
    dataIndex: "pmaxBuildTime",
    align: "center",
  },
  // {
  //   title: "辅材",
  //   width: "100px",
  //   scopedSlots: { customRender: "pUnit" },
  //   dataIndex: "pUnit",
  //   align: "center",
  // },
  // {
  //   title: "单位辅材量",
  //   width: "100px",
  //   scopedSlots: { customRender: "pUnit" },
  //   dataIndex: "pUnit",
  //   align: "center",
  // },
  // {
  //   title: "主材",
  //   width: "100px",
  //   scopedSlots: { customRender: "pUnit" },
  //   dataIndex: "pUnit",
  //   align: "center",
  // },
  // {
  //   title: "单位主材量",
  //   width: "100px",
  //   scopedSlots: { customRender: "pUnit" },
  //   dataIndex: "pUnit",
  //   align: "center",
  // },
  {
    title: "操作",
    width: "160px",
    scopedSlots: { customRender: "action" },
    fixed: "right",
    dataIndex: "action",
    align: "center",
  },
];
// const orderParam = ["pBcIds", "pName"];

export default {
  name: "ConstructionProject",
  components: {
    STable,
    SSearch,
    ColumnsSet,
    ConstructionProjectEdit,
  },
  data() {
    this.queryItems = queryItems;
    this.columns = columns;
    return {
      tableColumns: [],
      // 查询参数
      queryParam: {
        pBcIds: "24",
        pageNo: 1,
        pageSize: 10,
      },
      destroyOnClose: true,
      isShowBtns: [],
      visible: false,
      DrawerTitle: "",
      ConstructionProjectEdit: false,
      record: {},
      CompanyList: {},
      type: "",
      filters: {},
      CompanyListid: [],
      pageNo: 1,
      pageSize: 10,
    };
  },
  created() {
    this.GetCompaniesId();
    this.tableColumns = columns;
    this.changeColumns();
    this.filters = SelectOptions;
  },
  mounted() {
    this.isShowBtns = sessionStorage.getItem("isShowBtnsJF").split(",");
  },
  methods: {
    // 获取分公司id
    GetCompaniesId() {
      GetCompanies()
        .then((res) => {
          res.data.forEach((el) => {
            this.CompanyListid.push(el.bc_id);
            this.CompanyList[el.bc_id] = el.bc_name;
          });
        })
        .then(() => {
          this.queryParam.pBcIds = this.CompanyListid.toString();
          this.$refs.table.refresh(true);
          //当包含的分公司小于1个的时候，查询条件不显示分公司查询 表格不显示分公司名称
          if (this.CompanyListid.length < 1) {
            let data = [...this.queryItems];
            this.queryItems = data.filter((item) => item.label != "公司名称");
            if (this.queryItems.length == 0) {
              this.SSearchShow = false;
            }
            let data1 = [...this.columns];
            this.tableColumns = data1.filter(
              (item) => item.title != "公司名称"
            );
            this.columns = data1.filter((item) => item.title != "公司名称");
          }
        });
    },
    CompaniesResult(a) {
      return this.CompanyList[a];
    },
    // 自定义表头列
    changeColumns() {
      let ColumnsSet = JSON.parse(
        localStorage.getItem("ConstructionProjectColumnsSet")
      );
      let value = ColumnsSet ? ColumnsSet : [];
      let selectColumns = columns.filter((el) => value.includes(el.dataIndex));
      this.tableColumns = selectColumns.length ? selectColumns : columns;
    },
    // 加载数据方法 必须为 Promise 对象
    loadData(parameter) {
      if (parameter) {
        this.queryParam.pageNo = parameter.pageNo;
        this.queryParam.pageSize = parameter.pageSize;
      } else {
      }
      // console.log(this.queryParam);
      let newObject = {};
      for (let el in this.queryParam) {
        if (this.queryParam[el] != "") {
          newObject[el] = this.queryParam[el];
        }
      }
      let orderParam = Object.keys(newObject);
      const param = Object.assign({}, parameter, this.queryParam);
      const requestParameters = this.$order(param, orderParam);

      const params = {
        ...requestParameters,
        verify: this.$verify(requestParameters, orderParam),
      };

      return GetProjectList(params).then((res) => {
        res.data.pageNo = res.data.page;
        res.data.totalCount = res.data.sum;

        return res.data;
      });
    },
    fetchUsers(value) {
      console.log(value);
      this.queryParam = value;
      if (this.queryParam.pBcIds == "") {
        this.queryParam.pBcIds = this.CompanyListid.toString();
      }
      this.$refs.table.refresh(true);
    },
    // changeTable(pagination, filters, sorter, { currentDataSource }) {
    //   // console.log(pagination, filters, sorter, { currentDataSource });
    // },
    handleExport() {
      this.confirmLoading = false;
      this.visible = true;
    },
    // 删除类别信息
    onDelete(record) {
      // console.log("record", record);
      const param = {
        pId: record.pid,
      };
      const orderParam = ["pId"];
      const requestParameters = this.$order(param, orderParam);
      const params = {
        ...requestParameters,
        verify: this.$verify(requestParameters, orderParam),
      };
      return DeleteProject(params).then((res) => {
        console.log(res);
        if (res.code == 200) {
          this.$message.success("删除成功");
          this.$refs.table.refresh(true);
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 弹窗
    afterVisibleChange(val) {
      // console.log("visible", val);
    },
    showDrawer(name, title, type, record) {
      this.DrawerTitle = title;
      this.visible = true;
      this[name] = true;
      this.record = record;
      this.type = type;
    },
    onClose() {
      this.visible = false;
      this.record = [];
      this.ConstructionProjectEdit = false;
      this.$refs.table.refresh(true);
    },
  },
};
</script>
<style lang="less" scoped>
.delName {
  color: red;
}
.editBtn {
  margin-right: 20px;
}
.table-button-flex {
  display: flex;
  justify-content: space-between;
}
</style>